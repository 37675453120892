<template>
  <div>
    <div> <p  class="category" style="margin-bottom: 29px;">访问记录</p>
    </div>
    <el-table
        :data="tableData"
        style="width: 100%">
      <el-table-column
          label="ID"
          width="180">
        <template slot-scope="scope">
          <span style="" >{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="ip"
          width="180"
      >
        <template slot-scope="scope">
          <span style="" >[{{ scope.row.country}}]{{scope.row.ip}}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="访问地址"
      >
        <template slot-scope="scope">
          <span style="">{{ scope.row.url}}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="来源"
      >
        <template slot-scope="scope">
          <span style="">{{ scope.row.referrer}}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="访问时间"
          width="180">
        <template slot-scope="scope">
          <span style="">{{ scope.row.create_time}}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        layout="prev, pager, next"
        :page-size=10
        @current-change="getCate"
        :page-count="total"
    >
    </el-pagination>

  </div>
</template>
<script>
import axios from "axios";
// import Dialog from "@/views/pages/getContentView.vue"


export default {
  name: "AccessView",
  data() {
    return {
      tableData: [],
      id: '',
      total: 100,
    }
  },
  components:{
  },
  created() {
    this.getCate();
  },
  methods: {
    getCate(page = 1){
      axios.get('/api/Access/index.html?page='+page).then((res)=>{
        if(res.data.code == 200){
          this.tableData = res.data.data;
          this.total = res.data.total
        }
      });
    }
  }
};
</script>
<style scoped lang=''>
</style>